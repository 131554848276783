import { useParams, Navigate, Link } from 'react-router-dom'; // Add Navigate for redirection
import { useEffect, useState } from 'react';
import Meta from './Meta';


function ServicePage() {
    const { slug } = useParams();
    const [service, setService] = useState(null);
    const [isNotFound, setIsNotFound] = useState(false); // State to handle 404
    const [openIndex, setOpenIndex] = useState(null);
    // const [servicesList, setServicesList] = useState([]);
    const [servicesCategorys, setServicesCategorys] = useState([]);
    const [categoryPosts, setCategoryPosts] = useState([]);
    const [navIndex, setNavIndex] = useState(null);

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };


    useEffect(() => {
        fetch(`https://www.fahimshakir.com/blog/wp-json/custom/v1/services/slug/${slug}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Service not found');
                }
                return response.json();
            })
            .then(data => {
                if (data && data.title) {
                    setService(data);
                    setIsNotFound(false);
                } else {
                    setIsNotFound(true);
                }
            })
            .catch(error => {
                console.error('Error fetching service:', error);
                setIsNotFound(true)
            });

        // fetch(`https://www.fahimshakir.com/blog/wp-json/custom/v1/services`)
        //     .then(response => {
        //         if (!response.ok) {
        //             throw new Error('Service not found');
        //         }
        //         return response.json();
        //     })
        //     .then(data => {
        //         if (Array.isArray(data) && data.length > 0) {
        //             setServicesList(data);
        //         } else {
        //             setServicesList([]);
        //         }
        //     })
        //     .catch(error => console.error('Error fetching services:', error));


        fetch(`https://www.fahimshakir.com/blog/wp-json/custom/v1/service-category-terms`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Service not found');
                }
                return response.json();
            })
            .then(data => {
                if (Array.isArray(data) && data.length > 0) {
                    setServicesCategorys(data); // Set the fetched data
                } else {
                    setServicesCategorys([]); // Set an empty array if no data
                }
            })
            .catch(error => console.error('Error fetching services:', error));

    }, [slug]);

    if (isNotFound) {
        return <Navigate to="/not-found" />;
    }

    if (!service) {
        return <div>.</div>;
    }

    var faqEntries = []
    if (service && typeof service.faqs === 'object' && !Array.isArray(service.faqs) && service.faqs !== null) {
        faqEntries = Object.entries(service.faqs);
    }

    const handleClick = async (id, index) => {
        try {
            // Call your API (replace with actual API URL)
            const response = await fetch(`https://www.fahimshakir.com/blog/wp-json/custom/v1/posts-by-tag?post_type=services&tag_ID=${id}`);

            const result = await response.json();
            if (Array.isArray(result) && result.length > 0) {
                setCategoryPosts(result); // Set the fetched data
                // setNavIndex(openIndex === index ? null : index);
                if (navIndex === index) {
                    setNavIndex(null); // Close the currently open category
                } else {
                    setNavIndex(index); // Open the new category
                }
            } else {
                setCategoryPosts([]); // Set an empty array if no data
                setNavIndex(null);
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };


    return (
        <>
            <Meta
                title={service.yoast_seo_title}
                description={service.yoast_seo_description}
                keywords={service.yoast_focus_keywords}
            />

            <div className="service-image"><h1>{service.title}</h1></div>
            <div className="conatainer service-page">
                <div className='row'>
                    <div className='col-5 left-sidebar'>
                        <div className="side-widget-navigation">
                            <div className="side-widget-container">
                                <aside className="tj-services-widget">
                                    <h2 className="side-title">All Services</h2>
                                    <div className="services_list">
                                        <div className="menu-all-services-container">
                                            <ul className="menu-all-services">
                                                {
                                                    servicesCategorys.map((data, index) => (
                                                        <li key={index} className={`menu-item ${navIndex === index ? 'open' : ''}`}>
                                                            <span onClick={() => handleClick(data.id, index)} data-id={data.id}>
                                                                {data.name}
                                                            </span>

                                                            {
                                                                navIndex === index && categoryPosts.length > 0 && (
                                                                    <ul className="nav-link">
                                                                        {
                                                                            categoryPosts.map((postData, postIndex) => (
                                                                                (slug === postData.slug) ?
                                                                                    <li key={postIndex} className='item-active'>
                                                                                        <span>
                                                                                            {postData.name}
                                                                                        </span>
                                                                                    </li>
                                                                                    :
                                                                                    <li key={postIndex}>
                                                                                        <Link to={'/'+postData.slug}>
                                                                                            {postData.name}
                                                                                        </Link>
                                                                                    </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                )
                                                            }
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>

                        <div className="service-post bg-cover mb-4">
                            <div className="content">
                                <h3> Need Any Types <br />  of Service <br /> from us</h3>
                                <Link to="/contact.php">FIND SOLUTION</Link>
                            </div>
                        </div>

                        <div class="contact-widget-container">
                            <div class="contact-info">
                                <div class="info-item">
                                    <div class="icon">
                                        <i aria-hidden="true" class="fa fa-envelope"></i>
                                    </div>
                                    <div class="content">
                                        <h5 class="title">Email Address:</h5>
                                        <span class="info"><a href="mailto:info@fahimshakir.com">info@fahimshakir.com</a></span>
                                    </div>
                                </div>
                                <div class="info-item">
                                    <div class="icon">
                                        <i class="fa fa-phone" aria-hidden="true"></i>
                                    </div>
                                    <div class="content">
                                        <h5 class="title">Phone Number:</h5>
                                        <span class="info"><a href="tel:+919718925775">9718925775</a>
                                        </span>
                                    </div>
                                </div>
                                <div class="info-item">
                                    <div class="icon">
                                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                                    </div>
                                    <div class="content">
                                        <h5 class="title">Our Address:</h5>
                                        <span class="info">Block C-11B, Top Floor, New Ashok Nagar,
                                        </span>
                                    </div>
                                </div>

                                <div class="contact-socials">
                                    <ul class="dot-style">
                                        <li>
                                            <a href="https://www.facebook.com/FahimShakirWebsiteDeveloper/">
                                                <i aria-hidden="true" class="fab fa-facebook-f fa-brands"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/fahimwebdeveloper/">
                                                <i aria-hidden="true" class="fab fa-instagram fa-brands"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/fahimshakir786">
                                                <i aria-hidden="true" class="fab fa-twitter fa-brands"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://in.linkedin.com/in/fahim-shakir/">
                                                <i aria-hidden="true" class="fab fa-linkedin-in fa-brands"></i>
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-7 right-side'>
                        <div className='box-container'>
                            <h2 className='title'>{service.sub_titile}</h2>
                            <div className="excerpt-container">
                                <p>{service.excerpt}</p>
                            </div>
                            <img className='featured-image' src={service.featured_image} alt="project" loading="lazy" width={100} />
                            <div dangerouslySetInnerHTML={{ __html: service.content }} />

                            <div class="service-details-list">
                                <div class="row justify-content-between">
                                    {
                                        service.our_goals &&
                                        <div class="col-lg-4">
                                            <div class="goal-list">
                                                <h3 class="details-title">Our Goals</h3>
                                                <div dangerouslySetInnerHTML={{ __html: service.our_goals }} />
                                            </div>
                                        </div>
                                    }
                                    {
                                        service.the_challenges &&
                                        <div class="col-lg-7">
                                            <div class="challenges-list">
                                                <h3 class="details-title">The Challenges</h3>
                                                <div dangerouslySetInnerHTML={{ __html: service.the_challenges }} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            {faqEntries.length > 0 &&
                                <div class="faq-content">
                                    <div class="faq-accordion">
                                        {
                                            faqEntries.map(([key, item], index) => (
                                                <div className={`details ${openIndex === index ? 'open' : ''}`} key={index}>
                                                    <div className="summary" onClick={() => handleToggle(index)}>
                                                        {item.title}
                                                    </div>
                                                    <p>{item.description}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='divider'></div>
        </>
    );
}

export default ServicePage;
