import React, { useState, useEffect } from "react";
import "../Header/Header.css";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaPinterest } from "react-icons/fa";
import { ImInstagram } from "react-icons/im";
import { FaLinkedinIn } from "react-icons/fa";
import { VscGithub } from "react-icons/vsc";
import { BsAndroid2 } from "react-icons/bs";
import { GoMail } from "react-icons/go";
import { FaSkype } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import logo from "../image/fs-logo.png";
import { NavLink, Link } from "react-router-dom";
import { FaBars } from "react-icons/fa6";
import { FaXmark } from "react-icons/fa6";

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.pageYOffset;
      if (offset > 180) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    setActive(true);
  };

  const closenave = () => {
    setActive(false);
  };

  const navbarClass = isSticky ? "nav-main sticky" : "nav-main";

  return (
    <>
      <header>
        <div className="inner">
          <div className="container p-0">
            <div className="inner-section d-flex justify-content-space align-items-center">
              <ul className="social_icons_wrapper">
                <li className="grid_item">
                  <a href="https://www.facebook.com/FahimShakirWebsiteDeveloper/" title="Facebook" target="_blank">
                    <FaFacebookF className="icon-color" />
                  </a>
                </li>
                <li className="grid_item">
                  <a href="https://twitter.com/fahimshakir786" title="Twitter" target="_blank">
                    <FaXTwitter className="icon-color" />
                  </a>
                </li>
                <li className="grid_item">
                  <a href="https://in.pinterest.com/fahimshakir786/_saved/" title="Pinterest" target="_blank">
                  <FaPinterest className="icon-color" />
                  </a>
                </li>
                <li className="grid_item">
                  <a href="https://www.instagram.com/fahimwebdeveloper/" title="Instagram" target="_blank">
                  <ImInstagram className="icon-color" />
                  </a>
                </li>
                <li className="grid_item">
                  <a href="https://in.linkedin.com/in/fahim-shakir/" title="Linkedin" target="_blank">
                  <FaLinkedinIn className="icon-color" />
                  </a>
                </li>
                <li className="grid_item">
                  <a href="https://github.com/fahim-web-developer" title="Github" target="_blank">
                  <VscGithub className="icon-color" />
                  </a>
                </li>
                {/* <li className="grid_item">
                  <a href="https://play.google.com/store/apps/details?id=com.fahimshakir2" title="Android App Download" target="_blank">
                  <BsAndroid2 className="icon-color" />
                  </a>
                </li> */}
              </ul>
              <div className="icon_list_items_header">
                <ul className="icon_list_items">
                  <li className="icon_list_item">
                    <a href="mailto:fahimshakir786@gmail.com">
                      <GoMail className="information" />
                      <span className="icon_list_text">fahimshakir786@gmail.com</span>
                    </a>
                  </li>
                  <li className="icon_list_item">
                    <a href="skype:fahim.shakir40">
                    <FaSkype className="information" />
                      <span className="icon_list_text">fahim.shakir40</span>
                    </a>
                  </li>
                  <li className="icon_list_item">
                    <a href="tel:09718925775">
                      <IoIosCall className="information" />
                      <span className="icon_list_text">09718925775</span>
                    </a>
                  </li>
                  <li className="icon_list_item">
                    <a href="https://wa.me/919268751849">
                      <FaWhatsapp className="information" />
                      <span className="icon_list_text">09268751849</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="inner_two">
          <div className={`container ${navbarClass}`}>
            <div className="d-flex justify-content-space align-items-center">
              <div className="image">
                <Link to="/">
                  <img loading="lazy" src={logo} alt="Logo" className="image" width='150px' height='90px'/>
                </Link>
              </div>
              <div className="menue">
                <div className="raven-nav-menu-toggle">
                  <div className="raven-nav-menu-toggle-button" onClick={handleClick}>
                    <span className="fa fa-bars"></span>
                  </div>
                </div>

                <ul className={isActive ? "ul raven_nav_menu m-0 active" : "ul raven_nav_menu m-0"}>
                  <span className="close-btn" onClick={closenave}><i className="fa fa-times" aria-hidden="true"></i></span>
                  <li>
                    <NavLink to="/" end className={({ isActive }) => (isActive ? 'active' : '')}  onClick={()=>(setActive(false))}>Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="/about-us.php" className={({ isActive }) => (isActive ? 'active' : '')}  onClick={()=>(setActive(false))}>About Us</NavLink>
                  </li>
                  <li>
                    <NavLink to="/resume.php" className={({ isActive }) => (isActive ? 'active' : '')}  onClick={()=>(setActive(false))}>Resume</NavLink>
                  </li>
                  <li>
                    <NavLink to="/services.php" className={({ isActive }) => (isActive ? 'active' : '')}  onClick={()=>(setActive(false))}>Our Services</NavLink>
                  </li>
                  <li>
                    <NavLink to="/portfolio.php" className={({ isActive }) => (isActive ? 'active' : '')} onClick={()=>(setActive(false))}>Our Portfolio</NavLink>
                  </li>
                  <li>
                    <a href="/blog">Blog</a>
                    {/*<NavLink to="/blog">Blog</NavLink>*/}
                  </li>
                  <li>
                    <NavLink onClick={()=>(setActive(false))} to="/contact.php" className="button_link">Contact Us</NavLink>
                  </li>
                  </ul >
              </div >
              <div className="button_wrapper">
                <Link className="button_link" onClick={()=>(setActive(false))} to="/contact.php">Contact Us</Link>
              </div>
            </div >
          </div >
        </div >
      </header >
    </>
  );
};

export default Header;
